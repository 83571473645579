<template>
  <div class="nav-desktop-subdrop">
    <div class="nav-desktop-subdrop__side">
      <div class="nav-desktop-subdrop__wrapper">
        <div v-if="rootLink.header" class="nav-desktop-subdrop__title">
          {{ rootLink.header }}
        </div>

        <T3HtmlParser
          v-if="rootLink.description"
          :content="rootLink.description"
          class="nav-desktop-subdrop__text"
        />

        <T3Link
          v-if="rootLink.link"
          :to="{ link: rootLink.link, target: rootLink.target }"
          class="nav-desktop-subdrop__button"
        >
          <span @click="handleLinkClick">
            <span>{{ rootLink.buttonLabel }}</span>

            <SvgIconLinkArrow />
          </span>
        </T3Link>
      </div>

      <slot />
    </div>

    <div class="nav-desktop-subdrop__list-wrapper">
      <ul class="nav-desktop-subdrop__list">
        <li v-for="link in rootLink.children" :key="link.title">
          <template v-if="!link.spacer">
            <T3Link :to="getLink(link)" class="nav-desktop-subdrop__link">
              <span @click="handleLinkClick">
                {{ link.title }}
              </span>
            </T3Link>
          </template>

          <div v-else class="nav-desktop-subdrop__group">
            <span>{{ link.title }}</span>

            <ul v-if="subdrop(link.children)">
              <li v-for="sublink in link.children" :key="sublink.title">
                <T3Link
                  :to="getLink(sublink)"
                  class="nav-desktop-subdrop__link"
                >
                  <span @click="handleLinkClick">
                    {{ sublink.title }}
                  </span>
                </T3Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgIconLinkArrow from '~ui/assets/icons/icon-link-arrow.svg'

export default {
  components: { SvgIconLinkArrow },
  props: {
    rootLink: {
      type: Object,
      default: () => ({})
    },
    socialNavigation: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getLink({ link, target }) {
      return { link, target }
    },
    handleLinkClick() {
      this.$emit('link-click')
    },
    subdrop(array) {
      return Array.isArray(array) && array.length
    }
  }
}
</script>

<style lang="scss">
.nav-desktop-subdrop {
  $border-color: rgba(color(black), 8%);

  position: absolute;
  z-index: z-index(over);
  inset-inline: 0;
  top: $header-h;
  max-height: calc(100vh - $header-h);
  max-height: calc(100svh - $header-h);
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  visibility: hidden;
  opacity: 0;
  border-bottom: 1px solid $border-color;
  box-shadow: 1px 1px 3px $border-color;
  background: linear-gradient(
    90deg,
    var(--bg-100) 0% 50%,
    color(white) 50% 100%
  );
  transition:
    visibility 0.2s,
    opacity 0.2s;

  @include frame-content-container(large);

  .nav-desktop-link--subdrop-open & {
    visibility: visible;
    opacity: 1;
  }

  &__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(100px);
    grid-column: 1 / 3;
    color: color(black);
    background-color: var(--bg-100);
    border-right: 1px solid $border-color;
    padding-block: rem(40px);
    padding-right: rem($container-padding);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(24px);
  }

  &__title {
    font-size: rem(24px);
    font-weight: 700;
  }

  &__text p {
    font-size: rem(16px);
  }

  &__button {
    display: flex;
    align-items: center;
    gap: rem(4px);
    font-weight: 600;
    font-size: rem(16px);
    position: relative;

    &:is(:hover, :active, :focus) {
      color: inherit;

      & svg {
        transform: translateX(2px) translateY(-2px);
      }
    }

    & svg {
      height: 24px;
      width: 24px;
      transition: transform 0.2s;

      path {
        fill: color(secondary);
      }
    }
  }

  &__list {
    &-wrapper {
      grid-column: 3 / -1;
      background-color: color(white);
      padding-right: 0;
      padding: rem(40px);
    }

    display: grid;
    gap: rem(32px);
    grid-template-columns: repeat(auto-fit, minmax(rem(230px), 1fr));
  }

  &__link {
    display: block;
    font-size: rem(16px);
    color: color(black);

    &.router-link-exact-active {
      text-decoration: 2px underline color(primary);
      text-underline-offset: 4px;
    }
  }

  &__group ul {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
  }

  &__group > span {
    display: inline-block;
    color: var(--font-100);
    font-weight: 500;
    font-size: rem(14px);
    text-transform: uppercase;
    margin-bottom: rem(16px);
    text-decoration: 2px underline $border-color;
    text-underline-offset: 4px;
  }
}
</style>
