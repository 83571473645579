<template>
  <div
    class="nav-desktop-link-container"
    :class="[
      { 'nav-desktop-link--with-subdrop': subdrop },
      { 'nav-desktop-link--subdrop-open': open }
    ]"
    @mouseover="subdrop && handleSubdropToggle(link.id)"
    @mouseleave="subdrop && handleSubdropToggle()"
  >
    <T3Link :to="getLink(link)" class="nav-desktop-link" active-class>
      <span @click="handleLinkClick">
        {{ link.title }}

        <SvgIconArrowMenu v-if="subdrop" />
      </span>
    </T3Link>

    <NavDesktopSubdrop
      v-if="subdrop"
      :root-link="link"
      @link-click="handleLinkClick"
    >
      <slot />
    </NavDesktopSubdrop>
  </div>
</template>

<script>
import SvgIconArrowMenu from '~ui/assets/icons/icon-arrow-menu.svg'

import NavDesktopSubdrop from './NavDesktopSubdrop.vue'

export default {
  components: { NavDesktopSubdrop, SvgIconArrowMenu },
  props: {
    link: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subdrop() {
      return Array.isArray(this.link.children) && this.link.children.length
    }
  },
  methods: {
    getLink({ link, target }) {
      return { link, target }
    },
    handleLinkClick() {
      this.$emit('link-click')
    },
    handleSubdropToggle(id) {
      this.$emit('toggle-subdrop', id)
    }
  }
}
</script>

<style lang="scss">
.nav-desktop-link {
  &-container {
    height: 100%;
  }

  display: grid;
  align-items: center;
  height: 100%;
  position: relative;
  color: currentColor;
  white-space: nowrap;
  text-decoration: none;
  outline-offset: -6px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: z-index(below);
    border-bottom: 3px solid var(--border-color);
    transform: rotateY(90deg);
    transition: transform 0.4s ease;
    opacity: 0.8;
  }

  &:is(:hover, :active, :focus) {
    color: inherit;
  }

  &.router-link-active {
    --border-color: #{color(secondary)};

    color: inherit;
  }

  &:hover::after,
  &--subdrop-open &::after,
  &.router-link-active::after {
    transform: rotateY(0);
  }

  & > span {
    display: flex;
    align-items: center;
    height: 100%;
    gap: rem(4px);
    padding: rem(18px) range-clamp(8px, 16px, 1100px, 1400px);

    & > svg {
      opacity: 0.8;
      height: 24px;
      width: 24px;
      transition: rotate 0.4s;

      & path {
        fill: currentColor;
      }
    }
  }

  &--with-subdrop & > span {
    padding-right: rem(8px);
  }

  &--subdrop-open & svg {
    rotate: 180deg;
  }
}
</style>
