<template>
  <div
    v-if="displayLocaleSwitcher"
    v-click-outside="closeLocaleSwitcher"
    class="nav-desktop-locale-switcher"
    :class="{ 'nav-desktop-locale-switcher--active': open }"
  >
    <button
      class="nav-desktop-locale-switcher__active"
      @click="toggleLocaleSwitcher"
    >
      <span>{{ activeLocaleObject.locale }}</span>

      <SvgIconArrowMenu />
    </button>

    <div class="nav-desktop-locale-switcher__list-container">
      <div class="nav-desktop-locale-switcher__list-wrapper">
        <div class="nav-desktop-locale-switcher__list-header">
          <span>{{ translation.localeSwitcherHeader }}</span>
        </div>

        <ul class="nav-desktop-locale-switcher__list">
          <li
            v-for="locale in localeNavigation"
            :key="locale.locale"
            class="nav-desktop-locale-switcher__list-item"
            @click="displayLink(locale) ? handleLinkClick(locale.pid) : null"
          >
            <component
              :is="displayLink(locale) ? 't3-link' : 'div'"
              :to="displayLink(locale) ? locale.localePath : null"
              class="nav-desktop-locale-switcher__list-link"
              :class="{
                'nav-desktop-locale-switcher__list-link--disabled':
                  !locale.available
              }"
            >
              <NavFlagIcon :iso-code="locale.locale" />

              <span>{{ locale.language }}</span>

              <SvgIconCheck v-if="locale.active" />
            </component>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconArrowMenu from '~ui/assets/icons/icon-arrow-menu.svg'
import SvgIconCheck from '~ui/assets/icons/icon-check.svg'

import NavFlagIcon from '../NavComponents/NavFlagIcon.vue'

export default {
  components: { NavFlagIcon, SvgIconArrowMenu, SvgIconCheck },
  props: {
    localeNavigation: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    translation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    displayLocaleSwitcher() {
      return this.localeNavigation.length > 1
    },
    activeLocaleObject() {
      return this.localeNavigation.find(({ active }) => active)
    }
  },
  methods: {
    handleLinkClick() {
      this.closeLocaleSwitcher()
      this.$emit('link-click')
    },
    toggleLocaleSwitcher() {
      this.$emit('toggle', !this.open)
    },
    closeLocaleSwitcher() {
      this.$emit('toggle', false)
    },
    displayLink({ active, available }) {
      return !(active || !available)
    }
  }
}
</script>

<style lang="scss">
.nav-desktop-locale-switcher {
  $base: &;

  &__active {
    display: flex;
    align-items: center;
    gap: rem(4px);
    height: rem(56px);
    background-color: transparent;
    color: currentColor;
    border-radius: em(2px);
    border: 1px solid var(--border-color);
    padding: em(12px) range-clamp(12px, 16px, 1100px, 1400px);
    font-size: rem(16px);
    font-family: inherit;
    text-transform: uppercase;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    transition: border-color 0.2s;

    & > svg {
      opacity: 0.8;
      height: 24px;
      width: 24px;
      transition: rotate 0.4s;

      & path {
        fill: currentColor;
      }
    }
  }

  &--active &__active {
    border-color: var(--border-color-active);

    & svg {
      rotate: 180deg;
    }
  }

  &__list {
    &-header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: rem(16px);
      color: var(--font-300);
      padding-inline: rem($container-padding);
      padding-block: calc(rem($container-padding) * 1)
        calc(rem($container-padding) / 2);
    }

    &-item {
      & > * {
        color: var(--font-100);
      }

      &:nth-child(even) {
        background-color: var(--bg-100);

        & > * {
          color: var(--font-300);
        }

        & .nav-flag-icon {
          border: 1px solid rgba(color(tarawera), 32%);
        }
      }
    }

    &-link {
      padding: rem($container-padding);
      display: flex;
      align-items: center;
      gap: rem(12px);
      outline-offset: -4px;

      &:is(a):is(:hover, :active, :focus) {
        color: color(primary);
      }

      & svg {
        margin-left: auto;

        & path {
          fill: color(primary);
        }
      }

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &__list-container {
    display: grid;
    grid-template-rows: 0fr;
    align-content: start;
    position: absolute;
    z-index: z-index(over - 1);
    top: $header-h;
    visibility: hidden;
    overflow: hidden;
    background-color: color(white);
    border-radius: 0 0 em(8px) em(8px);
    border: 1px solid rgba(color(black), 8%);
    border-top: none;
    transition:
      grid-template-rows 0.2s,
      visibility 0.2s;

    right: rem($container-padding);

    @include reset-container-padding(large) {
      right: calc((content-width(large) - 100%) / 2 * -1);
    }
  }

  &__list-wrapper {
    min-height: 0;
  }

  &--active &__list-container {
    grid-template-rows: 1fr;
    visibility: visible;
  }
}
</style>
