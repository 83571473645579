<template>
  <nav class="nav-desktop">
    <ul v-if="mainNavigation.length" class="nav-desktop__list">
      <li v-for="(link, index) in mainNavigation" :key="link.title">
        <NavDesktopLink
          :link="{ ...link, id: index }"
          :open="navDesktopLinkId === index"
          @link-click="closeNavDesktop"
          @toggle-subdrop="handleNavDesktopLinkSubdropToggle"
        >
          <div v-if="socialNavigation.length" class="nav-desktop__social">
            <p>{{ translation.socialListHeader }}</p>

            <ul class="nav-desktop__social-list">
              <li
                v-for="socialLink in socialNavigation"
                :key="socialLink.title"
              >
                <NavSocialLink :link="socialLink" @click="closeNavDesktop" />
              </li>
            </ul>
          </div>
        </NavDesktopLink>
      </li>
    </ul>

    <template v-if="buttonNavigation.length || localeNavigation.length > 1">
      <div class="nav-desktop__aside">
        <ul v-if="buttonNavigation.length" class="nav-desktop__buttons">
          <li
            v-for="button in buttonNavigation"
            :key="button.content"
            @click="closeNavDesktop"
          >
            <ButtonBase :button="button" />
          </li>
        </ul>

        <NavDesktopLocaleSwitcher
          :locale-navigation="localeNavigation"
          :open="isNavDesktopLocaleSwitcherOpen"
          :translation="translation"
          @link-click="closeNavDesktop"
          @toggle="handleNavDesktopLocaleSwitcherToogle"
        />
      </div>
    </template>
  </nav>
</template>

<script>
import { ButtonBase } from '~ui/components'

import NavDesktopLink from './NavDesktopLink.vue'
import NavDesktopLocaleSwitcher from './NavDesktopLocaleSwitcher.vue'
import NavSocialLink from '../NavComponents/NavSocialLink.vue'

export default {
  components: {
    ButtonBase,
    NavDesktopLink,
    NavSocialLink,
    NavDesktopLocaleSwitcher
  },
  props: {
    mainNavigation: {
      type: Array,
      default: () => []
    },
    buttonNavigation: {
      type: Array,
      default: () => []
    },
    socialNavigation: {
      type: Array,
      default: () => []
    },
    localeNavigation: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    translation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { isNavDesktopLocaleSwitcherOpen: false, navDesktopLinkId: null }
  },
  watch: {
    isMobile(isMobile) {
      if (isMobile) {
        this.closeNavDesktop()
      }
    }
  },
  methods: {
    handleNavDesktopLocaleSwitcherToogle(value) {
      this.isNavDesktopLocaleSwitcherOpen = value
    },
    handleNavDesktopLinkSubdropToggle(id) {
      this.navDesktopLinkId = typeof id === 'number' ? id : null
      this.isNavDesktopLocaleSwitcherOpen = false
    },
    closeNavDesktop() {
      this.navDesktopLinkId = null
      this.isNavDesktopLocaleSwitcherOpen = false
    }
  }
}
</script>

<style lang="scss">
.nav-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  :where(& ul) {
    margin: 0;
  }

  &__list {
    display: flex;
    align-items: center;
    height: 100%;

    & > li {
      height: 100%;
    }
  }

  &__aside,
  &__buttons {
    display: flex;
    gap: range-clamp(8px, 24px, 1100px, 1400px);
    align-items: center;
  }

  &__buttons .button-base {
    height: rem(56px);
    width: 100%;
    margin: 0;

    & > span {
      padding: em(16px) range-clamp(16px, 24px, 1100px, 1400px);
      height: 100%;
    }
  }

  &__social p {
    color: var(--font-200);
    font-size: rem(14px);
    font-weight: 500;
    margin: 0 0 rem(12px);
  }

  &__social-list {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8px);
  }

  :is(&__list, &__aside):first-child:last-child {
    margin-left: auto;
  }
}
</style>
